/*
AuthentView
*/

import React, { useEffect } from 'react';
import { Navigate, useNavigate  } from "react-router-dom";
import { globals, removeCookies, setCookies, adminConnected, getCookies } from '../Components/Globals/Globals';
import {DsAdminAuthent} from "datasync-admin-authent"
import { Button} from 'reactstrap';

const authent_form = {
    version : "1.0",
    Rows : [{
    Cols : [{
        Fields : [
        {
        name : "e_mail_admin",
        title : "Adresse électronique de l'administrateur",
        placeholder : "Votre adresse de courriel",
        input_type : "email",
        value:"",
        min_length : 5,
        max_length : 40,
        required  : true,
        },
        {
        name : "human_captcha",
        placeholder : "Je ne suis pas un robot",
        title : "Votre message",
        input_type : "captcha",
        min_length : -1,
        max_length : -1,
        required  : false,
        }
        ]
    }]
    }]
}

const AuthentView = () => {
    let navigate = useNavigate()
    let connected_user = getCookies()
    {/**
    useEffect(()=>{
        if (adminConnected()){
            navigate("/")
        }
    })
     */}

    const onAdminConnectHandler = (user) => {
        if (globals.parameters.debugging){
            console.log("onAdminConnectHandler user:", user);
        }
        //Set admin property
        //user["admin"] = user.current_user.data_blob.data_tier.e_mail == "pmabiala@me.com"?"Oui":"Non"
        //Admin property is tored in admin_users_table_guid with "Oui" or "Non" value

        //No needs to redirect admin user, just set cookies 
        setCookies(user.current_user.data_blob.data_tier)
        navigate("/authent")
    }
    
    //rendering
    return (
        <div className="view-container">
            {/* Full width main picture */}
                <div className="body-page-container">
                    {!adminConnected() && <>
                        <h4>Connexion</h4>
                        {/* Laïus introduction de la page*/}
                        <div className="introduction-container">
                            <p>Veuillez entrer vos informations, afin de vous authentifier et accèder à la console d'Administration.
                            <br/>Authentifiez-vous&nbsp;!</p>
                        </div>
                        
                        <DsAdminAuthent 
                            form = {authent_form}
                            obsolete_filter_format = {false}
                            datasync_url = {globals.datasync_service.url}
                            mailsync_url = {globals.mailsync.url}
                            token_email_display_sender = "ne_pas_repondre@thononadvent.com"
                            company_guid_disabled = {globals.datasync.thononadvent_company_guid}
                            company_guid = {"4"}
                            table_guid= {globals.datasync.admin_users_table_guid}
                            allow_spaces_in_json={globals.parameters.production}
                            beforeAdminGranted={(p_o_user_data_block)=>{
                                                                            try{
                                                                                console.log (`${p_o_user_data_block.data_blob.data_tier.e_mail} has requests access`)
                                                                                return (p_o_user_data_block.data_blob.data_tier.enabled == "Oui")
                                                                            }
                                                                            catch(e){
                                                                                alert(`beforeAdminGranted raises error ${e}`)
                                                                                console.error(`beforeAdminGranted raises error ${e}`)
                                                                            }
                                                                        }}
                            onAdminFailed = {(p_error_object) => {alert(`Authentication error raised with error_code:${p_error_object.code} and message:${p_error_object.msg}`)}}
                            onAdminGranted={(record)=>{onAdminConnectHandler(record);}}
                            onPartnerGranted={null}/>
                    </>}

                    {adminConnected() && <div>
                        {/* Welcome Panel */}
                        <h4>Administrateur authentifié</h4>
                        <p>
                            Bonjour <b>{connected_user.first_name} {connected_user.name}</b> Vous êtes désormais connecté(e) en administrateur(/trice).<br/>
                            Vous avez accès à la console d'administration des évènements et de la borne d'affichage de la vitrine.<br/>
                            <i>Le webmestre.</i>
                        </p>
                    </div>}

                    {globals.parameters.cookie_debugging &&
                    <div>
                        <br/>
                        <Button onClick={()=>{setCookies({name:"Mab", first_name:"Pat", admin:"1", enabled:1}); navigate("/authent")}}>setCookie debugging</Button>
                        <br/>
                        <Button onClick={()=>{removeCookies(); navigate("/authent")}}>removeCookies debugging</Button>
                    </div>}

            </div>
        </div>
        );
}

export default AuthentView;