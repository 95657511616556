import React from 'react'
import { globals } from '../Globals/Globals'
import { render } from 'react-dom'
import '../../css/ThononAdventTemplate.css'
import { Button} from 'reactstrap';
import { AddToCalendarButton } from 'add-to-calendar-button-react';



const EventCard = (props) => {

    const pad = (s) => { return (s < 10) ? '0' + s : s; }

    const calendar_button = (p_o_calendar) => {
        

        return(
            <div>
                <AddToCalendarButton
                name={props.data.title}
                description={props.data.text}
                startDate={`${props.data.year}-${pad(props.data.month)}-${pad(props.data.day)}`}
                options={['Apple','Google','Outlook.com','iCal']}
                timeZone="Europe/Berlin"
                iCalFileName="Evenement-Eglise-adventiste-thonon"
                useUserTZ={true}
                label={"Ajouter au calendrier"}
                location={'9 rue Jean Blanchard, 74200 Thonon-les-Bains'}
                hideTextLabelButton={false}
                />
            </div>
            
        )
    }

    const media_button = (media_name) => {
        const isPicture = ["jpeg","jpg","png","pdf"];
        const isVideo = ["mp4","mov","avi"];
        const isAudio = ["mp3","wav","raw"];

        let split_media_name = media_name.split(".");
        let extension =  split_media_name[split_media_name.length-1];
        let media_button_class_name = ""

        //alert(extension)

        if (isPicture.includes(extension)){
            media_button_class_name = "media_button picture"
        } 

        if (isVideo.includes(extension)){
            media_button_class_name = "media_button video"
        } 

        if (isAudio.includes(extension)){
            media_button_class_name = "media_button audio"
        } 

        if (media_button_class_name.length == 0)
            return(<></>)
            
        return(
            <Button 
                className={media_button_class_name}
                onClick={()=>{
                    window.open(`${globals.services.event_media}/${props.data.media_filename}`, '_blank');}}>
            </Button>
            
        )
    }
    
        return(
            <div className="event-container" itemscope itemtype="https://schema.org/Event" isAccessibleForFree="true" >
                
                <div className='event-date'>
                    <time itemprop="startDate" datetime={`${props.data.year}-${pad(props.data.month)}-${pad(props.data.day)}`}>{`${props.data.day}/${props.data.month}/${props.data.year}`}</time>
                </div> 

                <div className='event-title'>
                    {props.data.title}
                </div>

                <div className='event-description'>
                    {props.data.text}
                </div>

                <div className='media-button-container'>
                    {media_button(props.data.media_filename)}
                </div>
                
                <div className='event-button-calendar'>
                    {calendar_button({day:props.data.day, month:props.data.month, year:props.data.year})}
                </div>

            </div>
        )
    }

export default EventCard;