import React, { useState } from 'react'
import "./StickyMenu3.css"
import {globals, getCookies, adminConnected, superAdminConnected, removeCookies} from '../Globals/Globals'

function StickyMenu3 (props) {
    let navbar_top_ref = React.createRef();
    let navbar_intitial_offset_top = -1;
    let [Toggle,setToggle] = useState(false);
    let [Toggle2,setToggle2] = useState(false);
    let [Collapsed,setCollapsed] = useState(true);
    //let [navBackColor,setNavBackColor] = useState(props.navBackColor?props.navBackColor:"transparent");
    let [navBackColor,setNavBackColor] = useState(props.navBackColor?props.navBackColor:"black");//Force back color to black
    let [scrollTriggerCeil,setScrollTriggerCeil] = useState(props.scrollTriggerCeil?props.scrollTriggerCeil:40);
/*
    React.useEffect(
        () => {
            window.addEventListener('scroll', function() {
                if (navbar_top_ref.current){
                    if (navbar_intitial_offset_top == -1)
                        navbar_intitial_offset_top = navbar_top_ref.current.offsetTop;
                    
                    if (globals.parameters.sticky3_debug){
                        console.clear()
                        console.log("window.scrollY => ", window.scrollY)
                        console.log("navbar_intitial_offset_top => ", navbar_intitial_offset_top)
                        console.log("navbar_top_ref.current.offsetTop => ", navbar_top_ref.current.offsetTop)
                    }

                    //Stick process
                    if (window.scrollY > navbar_intitial_offset_top) {
                        navbar_top_ref.current.classList.add('fixed-menu-top');

                        if (!props.auto_padding){
                            // add padding top to show content behind navbar
                            let navbar_height = navbar_top_ref.current.offsetHeight;
                            document.body.style.paddingTop = navbar_height + 'px';
                        }
                    } else {
                        navbar_top_ref.current.classList.remove('fixed-menu-top');

                        // remove padding top from body
                        if (!props.auto_padding) document.body.style.paddingTop = '0';
                    }

                    //Back Color process
                    if (window.scrollY > scrollTriggerCeil) {
                        //Change back color as scroll position is greater than nav bar heigth

                        //Set scrollabled background color
                        if (globals.parameters.sticky3_debug)
                            console.log("set Opaque")
                            
                        setNavBackColor(props.navBackScrolledColor?props.navBackScrolledColor:"black");
                    } else {
                        //Restore initial background color
                        if (globals.parameters.sticky3_debug)
                            console.log("set Transparent")

                        //setNavBackColor(props.navBackColor?props.navBackColor:"transparent");
                        setNavBackColor(props.navBackColor?props.navBackColor:"black");//Force back color to black
                    }
                }
            });
        }
    )
*/
    const rendering = () => {
        return(
            <>
                <nav 
                    ref={navbar_top_ref} 
                    id="navbar_top" 
                    className="navbar navbar-expand-lg navbar-dark"
                    style={{backgroundColor:`${navBackColor}`}}
                    >
                    <div className="container">
                        <div className='navbar-website-icon'/>
                        <a className="navbar-brand" href="/">{globals.parameters.website}</a>
                        <button onClick={()=>{setCollapsed(!Collapsed);}} className={`navbar-toggler ${Collapsed?"collapsed":""}`} type="button" data-toggle="collapse" data-bs-target="#main_nav" aria-expanded={`${Toggle?"true":"false"}`} aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${Collapsed?"collapse":"collapse show"}`} id="main_nav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item"><a className="nav-link" href="/">Accueil</a></li>
                                <li className="nav-item"><a className="nav-link" href="/dailyworship">Méditation du jour</a></li>
                                {/** 
                                <li className="nav-item"><a className="nav-link" href="/chain">{globals.menu.chainmenu}</a></li>*/}
                                
                                <li className="nav-item"><a className="nav-link" href="."
                                    onClick={() => {alert(`${globals.menu.who_are_we} - version ${globals.menu.version}`)}}
                                    >{globals.menu.about_us}</a></li>

                                {/**  Admin submenu */} 
                                {globals.parameters.admin_menu_enabled &&
                                <li className="nav-item dropdown" >
                                    <a className={`nav-link dropdown-toggle pointer ${Toggle ? "show" : ""}`} data-toggle="dropdown" 
                                        onClick={() => {
                                                if (globals.parameters.cookie_debugging) 
                                                    console.log(`Cookie value:${getCookies()}`);
                                                    console.log(`adminConnected:${adminConnected()?"True":"False"}`);
                                                if (adminConnected()){
                                                    setToggle(!Toggle);
                                                    setToggle2(false);
                                                }
                                                else{
                                                    setToggle2(!Toggle2);
                                                    setToggle(false)
                                                }
                                                }}>Administration</a>
                                    {!adminConnected() && 
                                    <ul className={`dropdown-menu dropdown-menu-right ${Toggle2 ? "show" : ""}`}>
                                        <li className="nav-item"><a className="dropdown-item" href="/authent">{globals.menu.login}</a></li>
                                    </ul>}

                                    {adminConnected() && 
                                    <ul className={`dropdown-menu dropdown-menu-right ${Toggle ? "show" : ""}`}>
                                        <li className="nav-item"><a className="dropdown-item" href="/eventsadmin">{globals.menu.eventsadmin}</a></li>
                                        {superAdminConnected() && <>
                                        <li className="nav-item"><a className="dropdown-item" href="/playlist">{globals.menu.terminaladmin}</a></li>
                                        <li className="nav-item"><a className="dropdown-item" href="/preview">{globals.menu.terminalpreview}</a></li>
                                        <li className="nav-item"><a className="dropdown-item" href="/terminal">{globals.menu.terminal}</a></li> </>}
                                        <li className="nav-item"><a className="dropdown-item" href="/" onClick={()=>{removeCookies()}}>{globals.menu.logoff}</a></li>
                                    </ul>}
                                </li>}
                            
                            </ul>

                        </div> {/*<!-- navbar-collapse.// -->*/}
                    </div> {/*<!-- container-fluid.// -->*/}
                </nav>
            </>
        )
    } 

    return(
        <>
            {rendering()}
        </>
    )
}

export default StickyMenu3;