import React, { Component } from 'react';
import {Button} from 'reactstrap';
import Cookies from 'universal-cookie';
import EventForm from '../Components/EventForm/EventForm';
import EventsRawTable from '../Components/EventsRawTable/EventsRawTable';
import {cGUID, globals} from '../../src/Components/Globals/Globals'

const cookies = new Cookies();
const _seconds = 1;
const _minutes = 60 * _seconds;
const _cookie_name = "thonon_user_was_authentified"

class EventsAdminView extends Component { 
  constructor(props){
    super(props);
    this.state={current_event:null, open_media_form:false}
  }

  /**
   * Triggered as media is turned into edition from RawTableDataSync component 
   */
  onEditEventHandler = async (selected_event_data) => {
    if (globals.parameters.debugging){
      console.clear()
      console.log("onEditEventHandler :: selected_data => ", selected_event_data)  
    }

    if (selected_event_data){
        //Set loaded Media data
        this.setState({current_event:selected_event_data, open_media_form:true})
    }
    else{
        alert("L'évènement à éditer est invalide !");
    }
  }

  render = () =>{
    return (
      <div className='view-container'>
        <div>
            <div className='media-container'>
              {/** Event Form */}
              {this.state.open_media_form && <>
                <h4>Formulaire d'évènement</h4>
                <EventForm
                  data={this.state.current_event}
                  onTerminate={()=>{this.setState({current_event:null, open_media_form:false})}}//Return to table mode
                  onClose={()=>{this.setState({current_event:null, open_media_form:false})}}//Return to table mode
                  />
              </>}
              
              {!this.state.open_media_form && <>
                <h4>{globals.menu.eventsadmin}</h4>
                <div className='top-media-toolbar'>
                  <Button color="primary" onClick={()=>{this.setState({open_media_form:true})}}>Nouvel évènement</Button>
                </div>
                {/** EventsRawTable */}
                <EventsRawTable
                  onEditEvent = {this.onEditEventHandler}
                />
              </>}
            </div>
        </div>
      </div>
    );
  }
}

export default EventsAdminView;
