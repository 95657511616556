import React, {Component} from "react";
import {Table, Input, Button, Label, DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import axios from 'axios';
import { globals } from "../Globals/Globals";

{/*
// get our fontawesome imports
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";*/}

//import ModalExample from "./Modal";

class EventsRawTable extends Component{
    constructor(props){
        super(props);
        this.state={Event_list:null};
    }

    componentDidMount = async () => {
        //Let us fetch Events from remote database       
        let EventListURL = `${globals.services.event_get}`;
        console.log("EventListURL:",EventListURL)
        let Events = await axios.get(EventListURL);
        this.setState({Event_list : Events.data.items});
    }

    onEditEventClickHandler = async (p_int_index_in_array) => {  
        console.clear();
        console.log("onEditEventClickHandler");

        try{
            //Broadcast edit mode to parent view
            if (this.props.onEditEvent)
                    this.props.onEditEvent(this.state.Event_list[p_int_index_in_array])
        }
        catch(e){
            console.error("onEditEventClickHandler : Invalid event with error :", e.message);   
        }
    }

    onDeleteEventClickHandler = async (p_int_index_in_array) => {
        let selected_Event = this.state.Event_list[p_int_index_in_array]

        if (window.confirm(`Supprimer l'évènement "${selected_Event.title}" ?`)){

            //Delete Event record from remote Database

            //call requete-service
            var serviceResult = null
            try{
                await fetch(
                        `${globals.services.event_delete}`,{
                            headers: {
                                        'Content-Type': 'application/json'},
                                        //'Content-Type': 'application/x-www-form-urlencoded'},
                            method: "POST", 
                            body: JSON.stringify({event_id:selected_Event.event_id})
                        }).then(
                    results => {return results.text();}
                    ).then(
                    (server_response) =>{
                        console.log("sever_response =>", server_response)
                        serviceResult = JSON.parse(server_response);
                        //------------------------------------------ Asynchronous promise result handle
                        //Get service aknowledge before resetting state values.
                        console.log("Event-delete => returns : ", serviceResult);

                        if (serviceResult.state === true) {
                            //Delete Event from state
                            let next_Event_list = this.state.Event_list;
                            next_Event_list = next_Event_list.slice(0, p_int_index_in_array).concat(next_Event_list.slice(p_int_index_in_array+1, next_Event_list.length))
                
                            this.setState({Event_list:next_Event_list})
                            alert("évènement supprimé.")
                        }
                        else{
                            alert("Erreur:" + serviceResult.message);
                        }
                        //--------------------------------------- End of promise Accept
                    } 
                    )
            } catch(err){
                alert(`Verifiez votre connexion wifi : ${err}`);
                return;
            }
        }
    }

    onDeleteEventClickHandler_NEXT = async (p_int_index_in_array) => {
        console.log("New onDeleteEventClickHandler")
        let selected_Event = this.state.Event_list[p_int_index_in_array]

        if (window.confirm(`Supprimer l'évènement "${selected_Event.title}" ?`)){

            //Delete Event record from remote Database
            let data = new FormData();
            ///data.append('file', JSON.stringify({event_id:selected_Event.event_id}));
            data.append('event_id', selected_Event.event_id);

            //call micro-service
            var serviceResult = null
            //axios.post(`${globals.services.event_delete}`, data, {}).then(res => { // then print response status
            axios.post(`${globals.services.event_delete}`, {event_id:selected_Event.event_id}, {}).then(res => { // then print response status
              console.log(res.statusText)
            }).then((server_response) =>{
                console.log("sever_response =>", server_response)
                serviceResult = JSON.parse(server_response);
                //------------------------------------------ Asynchronous promise result handle
                //Get service aknowledge before resetting state values.
                console.log("Event-delete => returns : ", serviceResult);

                if (serviceResult.state === true) {
                    //Delete Event from state
                    let next_Event_list = this.state.Event_list;
                    next_Event_list = next_Event_list.slice(0, p_int_index_in_array).concat(next_Event_list.slice(p_int_index_in_array+1, next_Event_list.length))
        
                    this.setState({Event_list:next_Event_list})
                    alert("évènement supprimé.")
                }
                else{
                    alert("Erreur:" + serviceResult.message);
                }
                //--------------------------------------- End of promise Accept
            }).catch(err => {
                alert(`Verifiez votre connexion wifi : ${err}`);
                return;
            });
            }
        }
    

    ReadWriteEventTableRendering = () => {
        let table_body = null;

        if (!((this.state.Event_list != null) && (this.state.Event_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun partenaire</td></tr>;
        }
        else{
            table_body = this.state.Event_list.map((EventItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteEventClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditEventClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier</Button></td>
                        <td className="col_desc"><p>{EventItem.day}/{EventItem.month}/{EventItem.year}</p></td>  
                        <td className="col_desc"><p>{EventItem.title}</p></td>
                        <td className="col_desc"><p>{EventItem.text}</p></td>
                        <td className="col_desc"><p>{EventItem.media_filename}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Date</th>
                        <th>Titre</th>
                        <th>Description</th>
                        <th>Media none/audio/video</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    ReadOnlyEventTableRendering = () => {
        let table_body = null;

        if (!((this.state.Event_list != null) && (this.state.Event_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun partenaire</td></tr>;
        }
        else{
            table_body = this.state.Event_list.map((EventItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        <td className="col_desc"><p>{EventItem.day}/{EventItem.month}/{EventItem.year}</p></td>  
                        <td className="col_desc"><p>{EventItem.title}</p></td>
                        <td className="col_desc"><p>{EventItem.text}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Titre</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    render(){
        return (
            <>
                {/* Master list of available Events */}
                {(this.props.onEditEvent)?this.ReadWriteEventTableRendering():this.ReadOnlyEventTableRendering()}
            </>);
    }
}

export default EventsRawTable;
