import '../../App.css'
import {globals} from '../Globals/Globals'

const FooterPage = () => {
    //rendering
    return(
         <div id="footer-container" className="col-12"> 
           <div id="footer-bottom">
             <div id="social-networks">
              {/*
               <div onClick={()=>{window.open(globals.links.linked_in)}} id='linked-in'/>
               <div onClick={()=>{window.open(globals.links.youtube)}} id='youtube'/>
               */}
               <div onClick={()=>{window.open(globals.links.facebook)}} id='facebook'/>
             </div>
             <div className="footer-copyrights">
                <div>&copy;2022-2024 Eglise Adventiste de Thonon-les-bains</div>
                 {/*
                <div>Propulsé par <a href="http://www.bibleforce.com" target="_blank">http://www.bibleforce.com</a></div>
                 */}
             </div>
           </div>
         </div>
     )
}

export default FooterPage;