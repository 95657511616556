import { FaEnvelope, FaClock, FaMobile, FaMapMarkerAlt } from 'react-icons/fa'
import React from 'react';
import {Col, Row} from 'reactstrap';
import './VisitUs.css';

const VisitUs = () =>{
    return (
        <div className='visit-us'>
          {/*-- SHow Room Google map location and contact --*/}
          <Row className="text-sm-left text-md-center text-xl-center" >
            <Col xs={{size:12}} md={{size:6}} lg={{size:6}}>
                <h4><FaMapMarkerAlt/><span className="etl-icon icon-map-pin mbr-iconfont mbr-iconfont-btn"></span>Adresse</h4>
                <p><span>9 Rue Jean Blanchard, 74200 Thonon-les-Bains </span></p>
            </Col>

            <Col xs={{ size: 12}} md={{ size: 6 }} lg={{ size: 6}}>
              <h4><FaClock/><span className="etl-icon icon-envelope mbr-iconfont mbr-iconfont-btn"></span>horaires</h4>
              {/*<p><span><b>Lun: </b>14h00 - 18h30</span><br/><span><b>Mar - Ven: </b>9h00 – 12h00 / 14h00 – 18h30</span><br/><span><b>Sam: </b>9h00  - 15h00 non-stop.</span></p>*/}
              <p>
                <span>
                  <b>Mercredi: </b>
                  19h-20h
                </span>
                
                <span>
                &nbsp; - &nbsp;
                  <b>Samedi: </b>
                  9h-13h
                </span>
              </p>
            </Col>
          </Row>

          {/*-- Google map --*/}
          <Row className="clear-md">
            <Col className="col-lg-12">
              <iframe width="100%" height="450" id="gmap_canvas" src="https://maps.google.com/maps?q=9%20Rue%20Jean%20Blanchard%2074200%20Thonon-les-Bains&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </Col>
          </Row>
        </div>
    )
}

export default VisitUs;