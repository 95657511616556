import React, {Component} from "react";
import { Col, Button, Input,Modal, ModalHeader, ModalBody, ModalFooter, Row, Form } from 'reactstrap';
import { globals } from "../Globals/Globals";
import {send_custom_emailV2} from 'datasync-mail';
import {GetFormValue3Tiers, SaveDataTierToDatasync} from 'datasync-core'
import {DsDynamicForm} from "datasync-dynamic-form";
import './ContactForm.css'

class ContactForm extends Component{
    constructor(props){
        super(props)

        /** Form declaration */
        this.contact_form = {
          version : globals.parameters.form_version,
          Rows : [{
          Cols : [{
              Fields : [
              {
              name : "last_name",
              title : "Nom de famille",
              placeholder : "Nom de famille",
              input_type : "text",
              min_length : 3,
              max_length : 20,
              required  : true,
              },
              {
              name : "first_name",
              placeholder : "Prénom",
              input_type : "text",
              min_length : 3,
              max_length : 20,
              required  : true,
              },
              {
              name : "e_mail",
              title : "Adresse électronique",
              placeholder : "courriel",
              input_type : "email",
              min_length : 5,
              max_length : 40,
              required  : true,
              },
              {
              name : "contact_message",
              placeholder : "Ecrivez nous...",
              title : "Votre message",
              input_type : "memo",
              min_length : -1,
              max_length : -1,
              required  : true,
              },
              {
              name : "human_captcha",
              placeholder : "Je ne suis pas un robot",
              title : "Votre message",
              input_type : "captcha",
              min_length : -1,
              max_length : -1,
              required  : false,
              }
              ]
          }]
          }]
        }
        
    }

  broadcastEmailConfirmation = (p_saved_contact_form) =>{
      let saved_contact_form = p_saved_contact_form.data_blob
      console.log("saved_contact_form ->", saved_contact_form)
      //Post user contact to thonon advent staff
      send_custom_emailV2(
        {
          mailsync_url:globals.mailsync.url,
          from:globals.mailer.ne_pas_repondre_email,
          email:globals.mailer.thonon_advent_staff_mailing_list,/* OVH mailing group */
          subject:globals.mailer.contact_request_mail_subject,
          prenom:GetFormValue3Tiers(saved_contact_form,"first_name"),
          nom:GetFormValue3Tiers(saved_contact_form,"last_name"),
          msg:GetFormValue3Tiers(saved_contact_form,"contact_message"),
          template:`<H1>${globals.mailer.contact_request_mail_subject}</H1><br/>
                    Vous venez de recevoir un message depuis le site adventiste thonon.adventiste.org  <br/>
                    <b>Nom du contact:</b> {{nom}} <br/>
                    <b>Prénom du contact:</b> {{prenom}} <br/>
                    <b>Mail du contact:</b> {{email}} <br/>
                    <b>Message du contact:</b>{{msg}}<br/><br/>
                    {{signature}}`,
          signature:globals.mailer.signature,
          onMailSent:(mail_parameter) => {
            //Send confirmation to user
            send_custom_emailV2(
                {
                mailsync_url:globals.mailsync.url,
                from:globals.mailer.ne_pas_repondre_email,
                email:GetFormValue3Tiers(saved_contact_form,"e_mail"),
                subject:globals.mailer.contact_request_mail_subject,
                prenom:GetFormValue3Tiers(saved_contact_form,"first_name"),
                nom:GetFormValue3Tiers(saved_contact_form,"last_name"),
                msg:GetFormValue3Tiers(saved_contact_form,"contact_message"),
                template:`<H1>${globals.mailer.contact_request_mail_subject}</H1><br/>
                          Bonjour <b>{{nom}}</b> <b>{{prenom}}</b><br/>
                         l'Eglise adventiste de Thonon-les-bains accuse réception de votre prise de contact<br/><br/>
                         <b>Votre message:</b>{{msg}}<br/><br/>
                         {{signature}}`,
                signature:globals.mailer.signature,
                onMailSent:(mail_parameter) => {
                    //Display confirmation message
                    alert(`Mr/Mme ${GetFormValue3Tiers(saved_contact_form,"last_name")} ${GetFormValue3Tiers(saved_contact_form,"first_name")} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${GetFormValue3Tiers(saved_contact_form,"e_mail")}`)
                  },
                  onMailFailed:(mail_parameter) => {
                    alert("Echec d'envoi du courriel de confirmation")
                  }
                })
          },
          onMailFailed:(mail_parameter) => {
            alert("Echec d'envoi du courriel de confirmation")
          }
        }
      )
  }
    
    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    Randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }
    
    contact_form_saved_handler = blob_data => {
        if (globals.parameters.debugging){
            console.log("contact_form_saved_handler => ", blob_data)
        }

        //Mail confirmation
        this.broadcastEmailConfirmation(blob_data);
    }

    contact_form_failed_handler = err => {
        if (globals.parameters.debugging)
            console.error("contact_form_failed_handler => save failed with this error" + err)

        if (globals.parameters.save_failed_alert)
            alert(err)
    }

    render = () => {
        return(
            <DsDynamicForm
                datasync_url = {globals.datasync_service.url}
                form = {this.contact_form}
                company_guid = {globals.datasync.thononadvent_company_guid}
                table_guid= {globals.datasync.contact_request_table_guid}
                clearOnSave={true}
                clearOnUpdate={true}
                onFormSaved = {this.contact_form_saved_handler}
                onFormFailed = {this.contact_form_failed_handler}
              />
            )
    }
}

export default ContactForm;