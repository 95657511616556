import React, {Component} from "react";
import { globals } from "../Globals/Globals";
import {send_custom_email} from 'datasync-mail';
import {DsDynamicForm} from "datasync-dynamic-form";
import {GetFormValue3Tiers, GetFormSelectedValues} from 'datasync-core'
import bible_request_template from "../../assets/templates/mailing/bible-request.txt"
import bible_request_acknowledge_template from "../../assets/templates/mailing/bible-request-acknowledge.txt"

class BibleForm extends Component{
    constructor(props){
        super(props)

        //console.log("../../assets/templates/mailing/bible-request.txt -->", bible_request_template)

        /** Mails template files */
        fetch(bible_request_template)
        .then(response => response.text())
        .then(bible_request_template => this.setState({ bible_request_template },()=>{console.log("bible-request.txt -->", this.state.bible_request_template)}))
        
        /** Mails template files */
        fetch(bible_request_acknowledge_template)
        .then(response => response.text())
        .then(bible_request_acknowledge_template => this.setState({ bible_request_acknowledge_template },()=>{console.log("bible-request-acknowledge.txt -->", this.state.bible_request_acknowledge_template)}))

        /** Fields structure declaration */
        this.bible_form = {
            version : "1.0",
            Rows : [{
                Cols : [{
                    Fields : [
                        {
                            "name":"civility",
                            "title":"Civilité",
                            "placeholder" : "Civilité",
                            "combo_list" : ["Monsieur", "Madame", "Mademoiselle"], 
                            "input_type" : "radio",
                            "min_length" : -1,
                            "max_length" : -1,
                            "required"  : false}]
                        }]
                    },
                {//Row-2
                Cols : [{
                    Fields : [
                    {
                        "name" : "last_name",
                        "title" : "Nom de famille",
                        "placeholder" : "Nom de famille",
                        "input_type" : "text",
                        "min_length" : 3,
                        "max_length" : 20,
                        "required"  : false,
                    }]},
                    {
                    Fields : [
                    {
                        "name" : "first_name",
                        "placeholder" : "Prénom",
                        "input_type" : "text",
                        "min_length" : 3,
                        "max_length" : 20,
                        "required"  : true,
                    }]}]},
                {//Row-3
                Cols : [{
                    Fields : [
                        {
                            "name":"adress",
                            "title":"Adresse postale",
                            "placeholder" : "Adresse postale",
                            "input_type" : "text",
                            "min_length" : -1,
                            "max_length" : -1,
                            "required"  : false}]
                        }]
                    },
                {//Row-4
                Cols : [{
                    Fields : [
                    {
                        "name" : "zip_code",
                        "title" : "Code postal",
                        "placeholder" : "Code postal",
                        "input_type" : "text",
                        "min_length" : 3,
                        "max_length" : 20,
                        "required"  : false,
                    }]},
                    {
                    Fields : [
                    {
                        "name" : "city",
                        "placeholder" : "Ville",
                        "input_type" : "text",
                        "min_length" : 3,
                        "max_length" : 20,
                        "required"  : false,
                    }]}]},
                {//Row-5
                Cols : [{
                    Fields : [
                    {
                        "name" : "phone",
                        "title" : "Téléphone",
                        "placeholder" : "Téléphone",
                        "input_type" : "text",
                        "min_length" : 3,
                        "max_length" : 20,
                        "required"  : false,
                    }]},
                    {
                    Fields : [
                    {
                        "name" : "e_mail",
                        "title" : "Adresse électronique",
                        "placeholder" : "courriel",
                        "input_type" : "text",
                        "min_length" : 5,
                        "max_length" : 40,
                        "required"  : true,
                    }]}]},
                {//Row-5
                    Cols : [{
                    Fields : [
                    {
                        "name" : "human_captcha",
                        "placeholder" : "Je ne suis pas un robot",
                        "title" : "Votre message",
                        "input_type" : "captcha",
                        "min_length" : -1,
                        "max_length" : -1,
                        "required"  : false,
                    }
                ]
            }]
            }]
        }
    
        this.clearObject = {
            nom:"",
            prenom:"",
            email:"",
            message:"",
            err_nom:[], err_prenom:[], err_email:[], err_message:[], err_captcha:[],
            has_changed:false,
            submitted:false,
            captcha:""
        }

        this.state = this.clearObject;
    }

  broadcastEmailConfirmation = (saved_bible_form) =>{
      //Mail free Bible request to Thonon Advent staff
      let l_sCivility_without_empty = GetFormSelectedValues(saved_bible_form.data_blob,"civility")[0]=="<"?"":GetFormSelectedValues(saved_bible_form.data_blob,"civility")

      if (send_custom_email(
        {
          mailsync_url:globals.mailsync.url,
          from:globals.mailer.ne_pas_repondre_email,//From - ne pas repondre
          email:globals.mailer.thonon_advent_staff_mailing_list,/* OVH ThononAdvent mailing group */
          subject:globals.mailer.bible_request_mail_subject,
          prenom:`${GetFormValue3Tiers(saved_bible_form.data_blob,"first_name")}`,
          nom:`${GetFormValue3Tiers(saved_bible_form.data_blob,"last_name")}`,
          civility:l_sCivility_without_empty,
          last_name: GetFormValue3Tiers(saved_bible_form.data_blob,"last_name"),
          first_name: GetFormValue3Tiers(saved_bible_form.data_blob,"first_name"),
          e_mail: GetFormValue3Tiers(saved_bible_form.data_blob,"e_mail"),
          adress: GetFormValue3Tiers(saved_bible_form.data_blob,"adress"),
          zip_code: GetFormValue3Tiers(saved_bible_form.data_blob,"zip_code"),
          city: GetFormValue3Tiers(saved_bible_form.data_blob,"city"),
          phone: GetFormValue3Tiers(saved_bible_form.data_blob,"phone"),

          signature:globals.mailer.signature,
          message:globals.mailer.bible_request_mail_message,
          template:this.state.bible_request_template,
          token:""
        }
      )){
          console.log("email sent to Thonon Advent Staff");

            //Mail confirmation back to user
            if (send_custom_email(
                {
                mailsync_url:globals.mailsync.url,
                from:globals.mailer.ne_pas_repondre_email,
                email:GetFormValue3Tiers(saved_bible_form.data_blob,"e_mail"),
                subject:globals.mailer.bible_request_mail_acknowledge_subject,
                civility:l_sCivility_without_empty,
                prenom:GetFormValue3Tiers(saved_bible_form.data_blob,"first_name"),
                nom:GetFormValue3Tiers(saved_bible_form.data_blob,"last_name"),
                signature:globals.mailer.signature,
                message:globals.mailer.bible_request_mail_acknowledge_message,
                template:this.state.bible_request_acknowledge_template,
                token:""
                }
            )){
                //alert(`${getFormSelectedValues(saved_bible_form.data_blob,"civility")} ${getFormValue3Tiers(saved_bible_form.data_blob,"last_name")} ${getFormValue3Tiers(saved_bible_form.data_blob,"first_name")} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${getFormValue3Tiers(saved_bible_form.data_blob,"e_mail")}`)
                alert(`${l_sCivility_without_empty} ${GetFormValue3Tiers(saved_bible_form.data_blob,"last_name")} ${GetFormValue3Tiers(saved_bible_form.data_blob,"first_name")} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${GetFormValue3Tiers(saved_bible_form.data_blob,"e_mail")}`)
            }
            else{
                alert("Echec d'envoi du courriel de confirmation")
            }
      }
      else{
        alert("Echec d'envoi du courriel de confirmation")
      }
  }
    
    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }
    
    bible_form_saved_handler = blob_data => {
        if (globals.parameters.debugging){
            console.log("bible_form_saved_handler => ", blob_data)
            alert("bible_form_saved_handler => Save succedeed")
        }

        //Mail confirmation
        this.broadcastEmailConfirmation(blob_data);
    }

    bible_form_failed_handler = err => {
        if (globals.parameters.debugging)
            console.error("bible_form_failed_handler => save failed with this error" + err)

        if (globals.parameters.save_failed_alert)
            alert(err)
    }

    render = () => {
        return(
            <DsDynamicForm
                datasync_url = {globals.datasync_service.url}
                form = {this.bible_form}
                company_guid = {globals.datasync.thononadvent_company_guid}
                table_guid= {globals.datasync.bible_request_table_guid}
                clearOnSave={true}
                clearOnUpdate={true}
                onFormSaved = {this.bible_form_saved_handler}
                onFormFailed = {this.bible_form_failed_handler}
              />
            )
    }
}

export default BibleForm;