import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Table, Button, UncontrolledCarousel } from "reactstrap";
import {globals, cTimeStamp, cGUID} from "../Globals/Globals"
import { DsBlob } from "datasync-blob";
import {GetDatasyncMediaOneBlock, GetFormValue3Tiers, SyncDelete} from "datasync-core"
import {DsSource} from "datasync-source"


class MediaRawTableDataSync extends Component{
    constructor(props){
        super(props);
        this.state={media_list:null};
    }

    componentDidMount = async () => {
        //Let us fetch media from remote database   
        if (globals.parameters.debugging) {
            console.log("FilesRawTableDataSync::componentDidMount with session=",this.props.current_session);
        }     
    }
  

    onEditFileClickHandler = async (p_int_index_in_array) => {        
        //Retrieve selected file id from selection index array
        let selected_idfile = this.state.media_list[p_int_index_in_array].idfile;

        //Broadcast edit mode to parent view
        if (this.props.onEditMedia)
                this.props.onEditMedia(selected_idfile)
    }

    onOpenFileClickHandler = async (p_int_index_in_array) => {        
        //Returns selected file object

        //Trigger Open viewer parent view
        if (this.props.onOpenMedia)
                this.props.onOpenMedia(this.state.media_list[p_int_index_in_array])
    }

    onDeleteFileClickHandler = async (mediaItemId) => {
        let l_o_mediaItem = Object.assign({}, this.state.media_list[mediaItemId]); 

        //Rely on low level saveFormToDatasync API
        if (!window.confirm("Souhaitez vous supprimer ce média ?"))return false;

        if (SyncDelete(l_o_mediaItem.data_guid)){
            /* Remove table item from item_list */
            alert("Média supprimé")
            /* Refresh display from caller */
            /* Remove media from media_list */
            this.setState({media_list: this.state.media_list.filter((item, j) => j !== mediaItemId)}, ()=>{console.log("delete mediaItemId=>",mediaItemId)});
        }
        else{
            alert(`Erreur de suppression`)
        }
    }

    fileTableRendering = () => {
        let table_body = null;

        if (!((this.state.media_list != null) && (this.state.media_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun Fichier</td></tr>;
        }
        else{
            table_body = this.state.media_list.map((fileItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        {/*<td className="col_logo"><FontAwesomeIcon icon={faEllipsisH} onClick={()=>{this.onDeletefile(parseInt(i,10))}} style={{cursor:"pointer"}}/></td>*/}
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer le média</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier le média</Button></td>
                        <td className="col_desc"><p>{fileItem.title}</p></td>  
                        <td className="col_desc"><p>{fileItem.full_file_name}</p></td>  
                        <td className="col_logo">
                                <DsBlob
                                    readOnly={true}
                                    class="pic"
                                    Caption="Charger le média ..." 
                                    data={GetFormValue3Tiers(fileItem.form,"file_data")}/>
                        </td>
                        <td className="col_desc"><p>{fileItem.time_stamp}</p></td>  
                        <td className="col_desc"><p>{fileItem.is_enabled=="1"?"Affiché":"Masqué"}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Titre</th>
                        <th>fichier</th>
                        <th>Preview</th>
                        <th>Date</th>
                        <th>Visible</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    fileGridRendering = () => {
        if (((this.state.media_list != null) && (this.state.media_list.length > 0) && (this.props.grid) ))
        return(
            <div className="events-columns-container">
                {
                    this.state.media_list.map((fileItem,i)=>{
                        return  <div key={i} className="event-column" onClick={()=>{this.onOpenFileClickHandler(parseInt(i,10))}}>
                                <p>{fileItem.data_blob.title}</p>
                                        <DsBlob
                                            readOnly={true}
                                            class="pic"
                                            Caption="Charger le média ..." 
                                            data={GetFormValue3Tiers(fileItem.data_blob.form,"file_data")}/>
                                
                                <p>{fileItem.data_blob.time_stamp}</p>
                            </div>})
                }
            </div>);
    }

    carouselRendering = () => {
        if ((this.state.carouselList != null) && (this.state.carouselList.length > 0) && (this.props.carousel)) 
        return(

            <div className={`${this.props.className}`}>
                <UncontrolledCarousel 
                    items={this.state.carouselList} 
                    className="actions"
                    wrap={"true"}
                    fade={true}
                    slide={false}
                    pause={false}
                    variant={"dark"}
                    indicators={this.props.indicators}
                    controls={this.props.controls}
                    interval = {this.props.interval}/>
            </div>);
    }

    render(){
        return (
            <>
                <DsSource
                    one_block_pull = {false}
                    session_guid = {this.props.current_session}
                    datasync_url = {globals.datasync_service.url}
                    company_guid = {globals.datasync.thononadvent_company_guid} 
                    table_guid= {globals.datasync.media_table_guid}
                    seek_filter_clause = {" true "}
                    
                    onAvailableItemsCount = {(available_items)=>{console.log(`DsSource: Available items => ${available_items}`)}}
                    onDataIsReady = {(media_blob_list)=>{
                        this.setState({media_list:media_blob_list}, ()=>{if (globals.parameters.debugging) console.log("15:getDatasyncMediaOneBlock has anwsered")})

                            /* Prepare Carousel structure */
                            if (this.props.carousel){
                            //Map to carousel
                                let carouselList = media_blob_list.map((item, i) => {return {
                                    key:i, 
                                    //src:GetFormValue3Tiers(item.data_blob.form,"file_data"),
                                    src:item.file_data,
                                    altText:item.title,
                                    caption:"", 
                                    header:""}});
            
                                    this.setState({carouselList:carouselList})
                            }
                    }}

                    onDataError = {(error_message)=>{alert(`Items fails with error => ${error_message}`)}}

                    options={{
                        selection: true,
                        search: true,
                        /*grouping: true, 2DO later*/
                        grouping: false,
                        showTitle: false,
                        actionsColumnIndex: -1,
                        pageSizeOptions: [5, 10, 20, 40, 200],
                    }}
                    />
                    
                {this.props.grid  && <>{this.fileGridRendering()}</>}
                {this.props.table && <>{this.fileTableRendering()}</>}
                {this.props.carousel && <>{this.carouselRendering()}</>}
            </>);
    }
}


MediaRawTableDataSync.propTypes = { 
    grid: PropTypes.bool,
    table: PropTypes.bool,
    carousel: PropTypes.bool
};

MediaRawTableDataSync.defaultProps = {
    grid:false,
    table:false,
    carousel:false
};



export default MediaRawTableDataSync;
