import React, { Component } from 'react';
import EventsRawTable from '../Components/EventsRawTable/EventsRawTable';

class DiaryView extends Component { 
  constructor(props){
    super(props);
  }

  render = () =>{
    return (
      <div className='view-container'>
        <div>
            <div className='media-container'>
                <h4>Agenda</h4>
                {/** EventsRawTable */}
                <EventsRawTable
                />
            </div>
        </div>
      </div>
    );
  }
}

export default DiaryView;
